import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Loader from '../components/Loader';
import Hero from '../components/home/Hero';
import Technologies from '../components/home/Technologies';
import Services from '../components/home/Services';
import WhyUs from '../components/home/WhyUs';
import Contact from '../components/home/Contact';

const Home = () => {
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  document.title = 'Webaily';

  const scrollHandler = () => {
    const { hash } = location;
    if (hash && document.getElementById(hash.slice(1))) {
      document.getElementById(hash.slice(1)).scrollIntoView({ behavior: 'smooth' });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      setTimeout(() => {
        scrollHandler();
      }, 2000);
    }, 1000);
  }, []);
  useEffect(() => {
    if (!isLoading) {
      scrollHandler();
    }
  }, [location]);
  return (
    <div>
      <Loader enable={isLoading} />
      <Hero />
      <Technologies />
      <Services />
      <WhyUs />
      <Contact />
    </div>
  );
};
export default Home;
