/* eslint-disable react/no-danger */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../assets/styles/blogPost.css';

const BlogPost = () => {
  const { id } = useParams();

  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const getContent = () => {
    setIsLoading(true);
    window.fetch(`https://cms.webaily.com/api/article.php/?id=${id}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.category === 'Webaily' || data.category === 'Webaily2') {
          setResponse(data);
          document.title = `Webaily | Article - ${data.title}`;
          setIsLoading(false);
        } else {
          setError(true);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setError(true);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (id) {
      getContent();
    }
  }, []);
  return (
    <div className="blogPost px-1 sm:px-5 w-full sm:w-10/12 mx-auto pt-36 min-h-[80vh] mx-auto">
      {!isLoading && response !== null ? (
        <div>
          <h1 className="text-3xl font-semibold">{response.title}</h1>
          <div className="blogPostContent bg-gray-700/30 rounded-lg filter backdrop-blur-sm mt-5 p-2 sm:p-5">
            <div dangerouslySetInnerHTML={{ __html: response.content }} className="content" />
          </div>
          <p className="mt-2">
            Updated
            {' '}
            {response.time}
          </p>
        </div>
      ) : (
        null
      )}   
      {isLoading ? (
        <h3>Loading...</h3>
      ) : (null)}
      {error ? (
        <h1>404 - Not Found</h1>
      ) : (null)}                
    </div>
  );
};
export default BlogPost;
