import logoFull from '../assets/img/logo-full.png';

const Column = ({ children }) => (
  <div className="flex flex col gap-1">
    {children}
  </div>
);

const Footer = () => (
  <div className="footer bg-[#020b18] px-5 sm:px-0 w-full sm:w-10/12 mx-auto py-5 relative bottom-0 left-0 right-0">
    <div className="flex justify-between">
      <Column>
        <img src={logoFull} alt="logo" className="max-w-[200px]" />
      </Column>
    </div>
    <hr className="my-5 border-gray-800" />
    <div className="flex justify-between text-gray-200">
      <a href="/post/114" className="hover:underline">
        Privacy Policy
      </a>
      <p className="text-right">
        {new Date().getFullYear()}
        {' '}
        &copy; Webaily.com, all rights reserved
      </p>
    </div>
  </div>
);
export default Footer;
