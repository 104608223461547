import { Routes, Route } from 'react-router-dom';
import './index.css';
import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';

import Menu from './components/Menu';
import Footer from './components/Footer';
import Home from './pages/Home';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import NotFound from './pages/NotFound';

import ProtectedRoute from './components/ProtectedRoute';

import localeEn from './lang/en.json';
import localePl from './lang/pl.json';

const Login = React.lazy(() => import('./pages/Login'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const Project = React.lazy(() => import('./pages/Project'));

const messages = {
  en: localeEn,
  pl: localePl
};

const App = () => {
  const [lang, setLang] = useState('en');
  useEffect(() => {
    const langLocalStorage = window.localStorage.getItem('lang');
    setLang(langLocalStorage !== null ? langLocalStorage : 'en');
  }, []);
  return (
    <div className="min-h-screen">
      <IntlProvider messages={messages[lang]} locale={lang} defaultLocale="pl">
        <Menu />
        <div>
          <Routes>
            <Route path="/blog" element={<Blog />} />
            <Route path="/post/:id" element={<BlogPost />} />
            <Route
              path="/login"
              element={(
                <React.Suspense fallback={<div className="min-h-[80vh]">...</div>}>
                  <Login />
                </React.Suspense>
            )}
            />
            <Route element={<ProtectedRoute />}>
              <Route
                path="/dashboard"
                element={(
                  <React.Suspense fallback={<div className="min-h-[80vh]">...</div>}>
                    <Dashboard />
                  </React.Suspense>
            )}
              />
              <Route
                path="/dashboard/project/:id"
                element={(
                  <React.Suspense fallback={<div className="min-h-[80vh]">...</div>}>
                    <Project />
                  </React.Suspense>
            )}
              />
            </Route>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </IntlProvider>
  
    </div>
  );
};
export default App;
