import { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { AiFillCheckCircle } from 'react-icons/ai';

const FinalizeContact = ({
  name, email, msg
}) => {
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);
  useEffect(() => {
    setSending(false);
    setError(false);
    setSent(false);
  }, []);
  const onChange = (value) => {
    setSending(true);

    const url = 'https://www.webaily.com/handle_form.php';
    const bodyFormData = new FormData();
    bodyFormData.append('from', email);
    bodyFormData.append('name', name);
    bodyFormData.append('msg', msg);
    bodyFormData.append('recaptcha_response', value);
    window.fetch(url, {
      method: 'POST',
      body: bodyFormData
    })
      .then((data) => data.json())
      .then((res) => {
        if (res.success !== true) {
          setSending(false);
          setError(true);
        } else {
          setSending(false);
          setSent(true);
        }
      })
      .catch((err) => {
        setSending(false);
        setError(true);
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  return (
    <div className="z-10 flex justify-center items-center fixed inset-0 bg-[#030b17] w-full">
      <form className="flex flex-col items-center gap-2">
        {!sent ? (
          <ReCAPTCHA
            sitekey="6Ld2F4MgAAAAAPTNxLu5WISu9EZrYZCGa3Try0dH"
            onChange={onChange}
            theme="dark"
          /> 
        ) : (
          <h2 className="flex gap-2 justify-center items-center text-green-500 text-4xl">
            <AiFillCheckCircle />
            Sent!
          </h2>
        )}
        {error && (
        <p className="text-2xl text-red-500">Error occurred</p>
        )}

        <p className="max-w-[300px] text-sm text-gray-300 text-justify mt-4">
          By filling out reCaptcha you accept the 
          {' '}
          <a href="/post/114" className="hover:underline">privacy policy</a>
          .
          The data entered in the form will be processed in order to provide an answer.
        </p>
        <button onClick={() => window.location.reload()} disabled={sending} type="button" className="transition-all w-full rounded-lg bg-gray-700 hover:bg-gray-600 h-10 text-lg mt-2 flex justify-center items-center">
          {sending && (
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
          )}
          {sending ? 'Sending...' : 'Back to the home'}
        </button>
      </form>
    </div>
  );
};
export default FinalizeContact;
