import { Navigate, Outlet } from 'react-router-dom';
import { readUserData } from '../lib/userDataHandler';

const ProtectedRoute = ({
  redirectPath = '/login',
  children
}) => {
  const data = readUserData();
  if (!data?.jwt) {
    return <Navigate to={redirectPath} replace />;
  }
  
  return children || <Outlet />;
};
export default ProtectedRoute;
