import {
  useEffect, useRef, forwardRef, useState
} from 'react';
import LeaderLine from 'react-leader-line';
import { gsap } from 'gsap';
import { FiCode } from 'react-icons/fi';
import { IoShapesOutline } from 'react-icons/io5';
import { MdOutlineManageAccounts } from 'react-icons/md';
import { AiOutlineCloudServer } from 'react-icons/ai';

const Service = forwardRef(({ title, icon }, ref) => (
  <div className="service max-w-[150px] md:max-w-[200px] pt-1 flex flex-col justify-start items-center" ref={ref}>
    <h3 className="text-5xl lg:text-7xl text-[#e91e63]">
      {icon}
    </h3>
    <h2 className="text-xs sm:text-xl md:text-2xl">{title}</h2>
  </div>
));

const Services = () => {
  const [initialized, setInitialized] = useState(false);

  const startRef = useRef();

  const endRef1 = useRef();
  const endRef2 = useRef();
  const endRef3 = useRef();
  const endRef4 = useRef();

  const services = [
    {
      title: 'web app development',
      ref: endRef1,
      icon: <FiCode />
    },
    {
      title: 'brand identity',
      ref: endRef2,
      icon: <IoShapesOutline />
    },
    {
      title: 'product management',
      ref: endRef3,
      icon: <MdOutlineManageAccounts />
    },
    {
      title: 'dedicated hosting',
      ref: endRef4,
      icon: <AiOutlineCloudServer />
    }
  ];
  const lineOptions = {
    dropShadow: true,
    dash: { animation: true },
    startSocket: 'bottom',
    endSocket: 'top',
    startPlug: 'disc',
    endPlug: 'behind',
    color: '#e91e63',
    hide: true
  };
  useEffect(() => {
    if (!initialized) {
      services.forEach(({ ref }) => {
        const line = new LeaderLine(startRef.current, ref.current, lineOptions);
        gsap.fromTo(ref.current, {
          opacity: 0,
          scale: 0.1,
          y: '-=50'
        }, {
          opacity: 1,
          scale: 1,
          y: 0,
          duration: 0.5,
          delay: 1,
          scrollTrigger: {
            trigger: '.services',
            start: 'top 70%',
            toggleActions: 'play none none reverse',
            immediateRender: false,
            onEnter: () => line.show('draw'),
            onLeaveBack: () => line.hide('draw')
          }
        });
      });
      setInitialized(true);
    }
  }, []);

  return (
    <div className="services py-36 text-center flex justify-center flex-col" id="services">
      <h1 className="text-2xl ourServicesHeader max-w-fit mx-auto p-2 font-semibold" ref={startRef}>our services</h1>
      <div className="flex justify-around mt-32">
        {services.map((service) => (
          <Service
            key={service.title}
            title={service.title}
            icon={service.icon}
            ref={service.ref}
          />
        ))}
      </div>
    </div>
  );
};
export default Services;
