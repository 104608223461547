/* eslint-disable prefer-const */
/* eslint-disable vars-on-top */
import { useEffect, useRef } from 'react';
import ThreeGlobe from 'three-globe';
import {
  WebGLRenderer, Scene,
  PerspectiveCamera,
  AmbientLight,
  DirectionalLight,
  Color,
  Fog,
  PointLight
} from 'three';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import countries from '../../assets/files/globe-data.json';

const Hero = () => {
  gsap.registerPlugin(ScrollTrigger);

  const canvas = useRef(null);

  let renderer,
    camera,
    scene;
  let Globe;

  function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  }
  function init() {
    const pixelRatio = window.devicePixelRatio;

    renderer = new WebGLRenderer({
      antialias: pixelRatio < 1,
      alpha: true,
      canvas: canvas.current,
      powerPreference: 'high-performance'
    });
    renderer.setPixelRatio(pixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);

    scene = new Scene();
    scene.add(new AmbientLight(0xbbbbbb, 0.3));
  
    camera = new PerspectiveCamera();
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
  
    const dLight = new DirectionalLight(0xffffff, 0.8);
    dLight.position.set(-800, 2000, 400);
    camera.add(dLight);
  
    const dLight1 = new DirectionalLight(0x7982f6, 1);
    dLight1.position.set(-200, 500, 200);
    camera.add(dLight1);
  
    const dLight2 = new PointLight(0x8566cc, 0.5);
    dLight2.position.set(-200, 500, 200);
    camera.add(dLight2);
  
    camera.position.set(0, 0, 250);
  
    scene.add(camera);
  
    scene.fog = new Fog(0x535ef3, 400, 2000);
  
    window.addEventListener('resize', onWindowResize, false);
  }

  function initGlobe() {
    Globe = new ThreeGlobe({
      waitForGlobeReady: true
    })
      .hexPolygonsData(countries.features)
      .hexPolygonResolution(3)
      .hexPolygonMargin(0.7)
      .showAtmosphere(true)
      .atmosphereColor('#3a228a')
      .atmosphereAltitude(0.25)
      .hexPolygonColor((e) => {
        if (
          ['KGZ', 'KOR', 'THA', 'RUS', 'UZB', 'IDN', 'KAZ', 'MYS'].includes(
            e.properties.ISO_A3
          )
        ) {
          return 'rgba(255,255,255, 1)';
        } return 'rgba(255,255,255, 0.7)';
      });
  
    Globe.rotateY(-Math.PI * (5 / 9));
    Globe.rotateZ(-Math.PI / 6);
    const globeMaterial = Globe.globeMaterial();
    globeMaterial.color = new Color(0x3a228a);
    globeMaterial.emissive = new Color(0x220038);
    globeMaterial.emissiveIntensity = 0.1;
    globeMaterial.shininess = 0.7;
  
    scene.add(Globe);
  }
  function animate() {
    Globe.rotation.y += 0.0005;
    camera.lookAt(scene.position);
    renderer.render(scene, camera);
    requestAnimationFrame(animate);
  }
  useEffect(() => {
    init();
    initGlobe();
    animate();
    if (window.pageYOffset === 0) {
      gsap.from(camera.position, {
        duration: 4,
        ease: 'power3.inOut',
        z: 800,
        x: '-=800'
      });
    }
    gsap.fromTo(camera.position, {
      z: 250
    }, {
      z: 800,
      x: '+= 500',
      scrollTrigger: {
        trigger: '.hero',
        start: 'top top',
        end: 'bottom 20%',
        scrub: true,
        toggleActions: 'play none none reverse',
        immediateRender: false
      }
    });
    gsap.fromTo(canvas.current, {
      opacity: 1,
      scale: 1
    }, {
      opacity: 0,
      scale: 0.5,
      scrollTrigger: {
        trigger: '.technologies',
        start: 'top 30%',
        toggleActions: 'play none none reverse',
        immediateRender: false
      }
    });
    const vsOpts = {
      slides: document.querySelectorAll('.v-slide'),
      list: ('.v-slides'),
      duration: 3.5,
      lineHeight: 50
    };
    const vSlide = gsap.timeline({
      paused: true,
      repeat: -1
    });
    vsOpts.slides.forEach((slide, i) => {
      vSlide.to(vsOpts.list, {
        duration: vsOpts.duration,
        y: i * -1 * vsOpts.lineHeight,
        ease: 'elastic.out(0.8, 1)'
      });
    });
    vSlide.play();
  }, []);
  return (
    <div className="hero h-screen">
      <canvas className="webgl max-w-full fixed" ref={canvas} />
      <div className="absolute max-w-full max-h-full flex justify-center items-center inset-0 z-0 text-left">
        <div>
          <h1 className="text-6xl lg:text-7xl font-bold mb-2">
            We
            {' '}
            <span>develop</span>
          </h1>
          <h1 className="text-3xl lg:text-4xl font-semibold h-[50px] flex items-center leading-0">
            innovating
            {' '}
            <div className="v-slider-frame text-[#e91e63] overflow-hidden h-[50px] ml-2">
              <ul className="v-slides list-none p-0 text-left">
                <li className="v-slide leading-[50px]">web apps</li>
                <li className="v-slide leading-[50px]">mobile apps</li>
                <li className="v-slide leading-[50px]">websites</li>
                <li className="v-slide leading-[50px]">brand identity</li>
              </ul>
            </div>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Hero;
