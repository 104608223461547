import { HiOutlineLightningBolt, HiOutlineLightBulb } from 'react-icons/hi';
import { BiSupport } from 'react-icons/bi';
import { AiOutlineTeam } from 'react-icons/ai';
import { FiCode, FiHeart } from 'react-icons/fi';

const features = [
  {
    title: 'Creativity',
    description: 'We are creative and think out of the box',
    icon: <HiOutlineLightBulb />,
    marginX: 'mx-12'
  },
  {
    title: 'Professionalism',
    description: 'We take care of every detail to satisfy the most demanding customers',
    icon: <FiCode />
  },
  {
    title: 'Experienced Team',
    description: 'We are young and experienced team',
    icon: <AiOutlineTeam />
  },
  {
    title: 'Passion',
    description: 'Work is primarily a passion for us',
    icon: <FiHeart />
  },
  {
    title: 'Technologies',
    description: 'We use the best and most efficient technologies for each project',
    icon: <HiOutlineLightningBolt />
  },
  {
    title: 'Customer Support',
    description: 'We provide technical support at the highest level',
    icon: <BiSupport />
  }
];

const Feature = ({
  title, description, icon, marginX = 'mx-5' 
}) => (
  <div className="relative group transition-all hover:scale-[1.05] feature h-80 min-w-[300px] max-w-[500px] w-1/4 m-6 inset-0" key={title}>
    <div className="absolute transition-all inset-0 rounded-lg bg-gradient-to-r from-pink-600 to-purple-600 opacity-75 group-hover:opacity-0 blur-md" />
    <div className="absolute transition-all inset-0 rounded-lg bg-gradient-to-r from-pink-400 to-purple-400 opacity-75 opacity-0 group-hover:opacity-100 blur-md" />
    <div className="absolute inset-1 rounded-lg bg-[#030b17]" />
    <div className="absolute inset-1 z-2 flex flex-col justify-center items-center">
      <div className="relative inset-0 w-28 h-28 flex justify-center items-center">
        <div className="absolute inset-0 rounded-full bg-gradient-to-r from-pink-600 to-purple-600 opacity-75 blur-sm" />
        <div className="absolute inset-1 rounded-full bg-[#030b17]/80 backdrop-blur-sm" />
        <div className="relative text-6xl">
          {icon}
        </div>
      </div>
      <div className={`${marginX} flex flex-col mt-8`}>
        <h3 className="text-2xl font-semibold mb-2">{title}</h3>
        <p>{description}</p>
      </div>
    </div>

  </div>
);

const WhyUs = () => (
  <div className="why-us text-center container mx-auto my-10">
    <h1 className="text-4xl font-bold mb-1">Why us?</h1>
    <h2 className="text-2xl font-semibold mb-8 text-pink-700 mx-2">Relationships are the most important thing for us</h2>
    <div className="flex flex-wrap justify-center flex-wrap">
      {features.map((feature) => (
        <Feature
          key={feature.title}
          title={feature.title}
          description={feature.description}
          icon={feature.icon}
          marginX={feature.marginX}
        />
      ))}
    </div>
  </div>
);
export default WhyUs;
