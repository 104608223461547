import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Divide as Hamburger } from 'hamburger-react';
import { gsap } from 'gsap';
import logoFull from '../assets/img/logo-full.png';

const MenuLinks = ({ classes = 'flex-row', mobile = false, close }) => (
  <div className={`text-gray-300 text-lg gap-5 font-medium flex ${classes}`}>
    <Link onClick={close} to="/#services" className={`hover:text-white ${mobile && 'mobile-menu-link'}`} smooth>Services</Link>
    <a href="/blog" className={`hover:text-white ${mobile && 'mobile-menu-link'}`}>Blog</a>
    <a href="/dashboard" className={`hover:text-white ${mobile && 'mobile-menu-link'}`}>Dashboard</a>
    <Link onClick={close} to="/#contact" className={`hover:text-white ${mobile && 'mobile-menu-link'}`} smooth>Contact</Link>
  </div>
);

const Menu = () => {
  const [isOpen, setOpen] = useState(false);
  const [tl] = useState(gsap.timeline({ paused: true }));
  useEffect(() => {
    tl
      .fromTo('.mobile-links-overlay', {
        opacity: 0,
        display: 'none'
      }, {
        opacity: 1,
        display: 'block'
      })
      .fromTo('.mobile-menu-link', {
        autoAlpha: 0,
        y: 48
      }, {
        autoAlpha: 1,
        y: 0,
        stagger: 0.1
      }, '-=0.4')
      .reverse();
  }, []);
  useEffect(() => {
    tl.reversed(!isOpen);
  }, [isOpen]);
  const close = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="menu bg-gray-900/60 filter backdrop-blur-sm fixed top-0 left-0 right-0 z-50">
        <div className="flex justify-between items-center p-2 mx-3">
          <div>
            <Link to="/" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
              <img src={logoFull} alt="logo" className="max-h-14" />
            </Link>
          </div>
          <div className="hidden sm:block">
            <MenuLinks />
          </div>
          <div className="block sm:hidden">
            <Hamburger toggled={isOpen} toggle={setOpen} />
          </div>
        </div>
      </div>
      <div className="hidden sm:hidden h-full mobile-links-overlay bg-gray-900/60 filter backdrop-blur-sm fixed top-0 left-0 right-0 z-30">
        <MenuLinks mobile classes="flex-col items-center text-2xl justify-center h-full pb-20" close={close} />
      </div>
    </>

  );
};
export default Menu;
