const saveUserData = (data) => {
  if (data !== null) {
    localStorage.setItem('u_data', window.btoa(JSON.stringify(data)));
  } else {
    localStorage.removeItem('u_data');
  }
};
const readUserData = () => {
  try {
    const retrievedObject = localStorage.getItem('u_data');
    return JSON.parse(window.atob(retrievedObject)); 
  } catch {
    return null;
  }
};
export { saveUserData, readUserData };
