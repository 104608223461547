import { useState } from 'react';
import { FaTwitter, FaGithub, FaRegEnvelope } from 'react-icons/fa';
import { MdKeyboardArrowRight } from 'react-icons/md';

import FinalizeContact from './FinalizeContact';
import TextField from '../TextField';

import leszekImg from '../../assets/img/leszek_sm.webp';

const socials = [
  {
    title: 'Twitter',
    icon: <FaTwitter />,
    url: 'https://twitter.com/webaily'
  },
  {
    title: 'Github',
    icon: <FaGithub />,
    url: 'https://github.com/webaily'
  }
];
const emails = [
  {
    email: 'hello@webaily.com'
  },
  {
    email: 'leszek@webaily.com'
  }
];

const MailElm = ({ email }) => (
  <div className="flex items-center gap-2 text-xl my-2">
    <FaRegEnvelope />
    <a href={`mailto:${email}`}>
      {email}
    </a>
  </div>
);

const Contact = () => {
  const [showFinalizeContact, setShowFinalizeContact] = useState(false);

  const [nameInpt, setNameInpt] = useState('');
  const [emailInpt, setEmailInpt] = useState('');
  const [msgInpt, setMsgInpt] = useState('');
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setShowFinalizeContact(true);
  };

  return (
    <div className="contact flex justify-center my-20 pt-28 lg:pt-20" id="contact">
      <div className="relative inset-0 flex justify-center items-center w-10/12">
        <div className="absolute inset-0 rounded-lg bg-gradient-to-r from-pink-600 to-purple-600 opacity-75 blur-sm" />
        <div className="absolute inset-1 rounded-lg bg-[#030b17]/80 backdrop-blur-sm" />
        <div className="relative flex w-full mx-5 md:mx-10 flex-col md:flex-row">
          <div className="basis-full py-10">
            <div className="flex items-center justify-between">
              <h1 className="text-4xl font-semibold">Contact Us</h1>
              <div className="transition-all flex gap-4 text-2xl my-3 text-gray-100 md:mr-3">
                {socials.map((social) => (
                  <a href={social.url} target="_blank" key={social.title} className="transition-all hover:text-white hover:scale-110" rel="noreferrer">
                    {social.icon}
                  </a>
                ))}
              </div>    
            </div>

            <hr className="border-dashed my-5 mr-2 border-gray-300" />
            <div>
              {emails.map((email) => (
                <MailElm key={email.email} email={email.email} />
              ))}
            </div>
            <hr className="border-dashed my-5 mr-2 border-gray-300" />
            <div className="flex items-center gap-5">
              <div className="relative flex justify-center items-center w-20 h-20">
                <div className="absolute inset-0 rounded-full bg-gradient-to-r from-pink-600 to-purple-600 opacity-50 z-10" />
                <div className="absolute inset-1 rounded-full z-10 overflow-hidden">
                  <img src={leszekImg} alt="team" className="relative rounded-full max-w-full w-auto z-0 top-[-15px]" />
                </div>
                <div className="absolute inset-1 z-20" />
              </div>
              <div className="flex flex-col gap-1">
                <a href="https://leszekk.eu" target="_blank" className="group text-xl md:text-2xl font-semibold flex items-center" rel="noreferrer">
                  Leszek Korzan
                  <MdKeyboardArrowRight className="transition-all opacity-0 relative -left-1 group-hover:opacity-100 group-hover:left-0" />
                </a>
                <p className="text-gray-300">CEO & Full Stack Dev</p>
              </div>
            </div>
          </div>
          <div className="basis-full py-10">
            <form onSubmit={handleSubmit} className="contact_form md:p-5 flex flex-col gap-2">
              <TextField value={nameInpt} onChange={(e) => setNameInpt(e.target.value)} type="text" placeholder="Your name" inputProps={{ maxLength: 50, required: true }} />
              <TextField value={emailInpt} onChange={(e) => setEmailInpt(e.target.value)} type="text" placeholder="Your email" inputProps={{ maxLength: 50, required: true }} />
              <textarea value={msgInpt} onChange={(e) => setMsgInpt(e.target.value)} className="w-full px-3 py-2 rounded-lg border-2 border-purple-700 focus:border-pink-600 bg-gray-200/90 backdrop-blur-sm text-gray-900 placeholder-gray-600 focus:outline-none focus:shadow-outline min-h-[45px] max-h-[120px]" rows={3} placeholder="Your message" maxLength={500} required />
              <button type="submit" className="transition-all rounded-lg bg-gradient-to-r hover:bg-gradient-to-l from-pink-600 to-purple-600 h-10 text-lg font-semibold hover:scale-[1.05] mt-2">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      {showFinalizeContact && (
        <FinalizeContact name={nameInpt} email={emailInpt} msg={msgInpt} />
      )}
    </div>
  );
};
export default Contact;
