import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const PostElm = ({
  title, description = '', path
}) => (
  <Link to={`/post/${path}`} className="relative group transition-all hover:scale-[1.05] feature h-44 min-w-[300px] m-6 inset-0" key={title}>
    <div className="absolute transition-all inset-0 rounded-lg bg-gradient-to-r from-pink-600 to-purple-600 opacity-75 group-hover:opacity-0 blur-md" />
    <div className="absolute transition-all inset-0 rounded-lg bg-gradient-to-r from-pink-400 to-purple-400 opacity-75 opacity-0 group-hover:opacity-100 blur-md" />
    <div className="absolute inset-1 rounded-lg bg-[#030b17]" />
    <div className="absolute inset-1 z-2 flex flex-col justify-start">
      <div className="mx-5 flex flex-col justify-between mt-8 h-full">
        <h3 className="text-2xl font-semibold mb-2">{title}</h3>
        <p className="mb-5 text-gray-200">
          {' '}
          {description}
        </p>
      </div>
    </div>
  </Link>
);

const Blog = () => {
  const [response, setResponse] = useState([]);
  useEffect(() => {
    document.title = 'Webaily | blog';
    window.fetch('https://cms.webaily.com/api/articles.php/?website=Webaily')
      .then((res) => res.json())
      .then((data) => setResponse(data))
      .catch(() => window.alert('Sorry, error. Try again later.'));
  }, []);
  return (
    <div className="blog px-5 sm:px-0 w-full sm:w-10/12 mx-auto pt-36 min-h-[80vh] mx-auto">
      <h1 className="text-3xl font-semibold text-center">Blog</h1>
      {response.length && response.success === undefined ? (
        <div className="mt-10 flex flex-wrap gap-5 md:gap-10 justify-center lg:justify-start">
          {response.map((obj) => (
            <PostElm key={obj.id} title={obj.title} description={obj.time} path={obj.id} />
          ))}
        </div>
      ) : (
        <h2 className="text-center mt-10">Loading...</h2>
      )}
      {response.success === false
        && <h2>No content.</h2>}
    </div>
  );
};
export default Blog;
