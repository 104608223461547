import { useEffect, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import {
  FaVuejs, FaPhp, FaPython, FaGithub, FaFigma, FaNodeJs, FaReact, FaWordpress
} from 'react-icons/fa';
import {
  SiTypescript,
  SiGatsby,
  SiExpress,
  SiFramer,
  SiJquery,
  SiFirebase,
  SiStrapi,
  SiAdobephotoshop,
  SiAdobeillustrator 
} from 'react-icons/si';

import useWindowSize from '../../hooks/useWindowSize';

import logoSvg from '../../assets/img/logo.svg';

const technologies = [
  {
    title: 'React',
    icon: <FaReact />,
    category: 'Freamworks'
  },
  {
    title: 'Vue',
    icon: <FaVuejs />,
    category: 'Freamworks'
  },
  {
    title: 'TypeScript',
    icon: <SiTypescript />,
    category: 'Languages'
  },
  {
    title: 'PHP',
    icon: <FaPhp />,
    category: 'Languages'
  },
  {
    title: 'Python',
    icon: <FaPython />,
    category: 'Languages'
  },
  {
    title: 'Next',
    category: 'Freamworks'
  },
  {
    title: 'Gatsby',
    icon: <SiGatsby />,
    category: 'Freamworks'
  },
  {
    title: 'Wordpress',
    icon: <FaWordpress />,
    category: 'CMS'
  },
  {
    title: 'GitHub',
    icon: <FaGithub />,
    category: 'Services'
  },
  {
    title: 'Figma',
    icon: <FaFigma />,
    category: 'Tools'
  },
  {
    title: 'Node',
    icon: <FaNodeJs />,
    category: 'Languages'
  },
  {
    title: 'Express',
    icon: <SiExpress />,
    category: 'Freamworks'
  },
  {
    title: 'GSAP',
    category: 'Libraries'
  },
  {
    title: 'Framer Motion',
    icon: <SiFramer />,
    category: 'Libraries'
  },
  {
    title: 'JQuery',
    icon: <SiJquery />,
    category: 'Libraries'
  },
  {
    title: 'Firebase',
    icon: <SiFirebase />,
    category: 'Services'
  },
  {
    title: 'Strapi',
    icon: <SiStrapi />,
    category: 'CMS'
  },
  {
    title: 'Photoshop',
    icon: <SiAdobephotoshop />,
    category: 'Tools'
  },
  {
    title: 'Illustrator',
    icon: <SiAdobeillustrator />,
    category: 'Tools'
  }
];

const TechChild = ({
  title, icon, category, rotation = 0, large = false, fontSize = '16px', highlight = false, setHoveredCategory
}) => {
  const { width } = useWindowSize();
  const getScale = () => {
    if (width > 1500) {
      return 1;
    } if (width > 1350) {
      return 0.9;
    } if (width > 768) {
      return 0.75;
    }
    return 0.6;
  };
  const getTranslateCoords = () => {
    if (width > 1700) {
      return '800px';
    }
    if (width < 768) {
      return '80vw';
    }
    return '50vw';
  };

  return (
    <div
      className={`
        circle-tech
        absolute
        inset-0
        ${large ? 'h-32' : 'h-[6.5rem]'}
        ${large ? 'w-32' : 'w-[6.5rem]'}
        m-auto
        flex
        justify-center
        items-center
        flex-col
        cursor-default
      `}
      style={{
        transform: `rotate(${rotation}deg) translateX(calc(${getTranslateCoords()}/2)) scale(${getScale()})`
      }}
      onMouseEnter={() => setHoveredCategory(category)}
      onMouseLeave={() => setHoveredCategory(null)}
    >
      <div className="absolute  transition-all inset-0 rounded-full bg-gradient-to-r from-pink-600 to-purple-600 opacity-75 blur-md" />
      <div className={`absolute transition-all inset-0 rounded-full bg-gradient-to-r from-pink-200 to-purple-200 ${highlight ? 'opacity-100' : 'opacity-0'} opacity-75 blur-md`} />

      <div className="absolute inset-1 rounded-full bg-[#030b17]/70 backdrop-blur-sm" />
      <div className="flex justify-center items-center flex-col" style={{ transform: `rotate(-${rotation}deg)` }}>
        {icon && (
          <div className={`relative text-white ${large ? 'text-[60px]' : 'text-[30px]'}`}>
            {icon}
          </div>
        )}
        <div className={`relative text-white mt-1 leading-none ${large ? 'text-[20px]' : `text-[${fontSize}]`}`}>
          {title}
        </div>
      </div>
    </div>
  );
};

const Technologies = () => {
  gsap.registerPlugin(ScrollTrigger);

  const [hoveredCategory, setHoveredCategory] = useState(null);

  useEffect(() => {
    gsap.fromTo('.technologies-all', {
      opacity: 0,
      scale: 0.1
    }, {
      opacity: 1,
      scale: 1,
      duration: 1,
      scrollTrigger: {
        trigger: '.technologies',
        start: 'top 30%',
        toggleActions: 'play none none reverse',
        immediateRender: false
      }
    });
    gsap.fromTo('.circle-tech', {
      opacity: 0
    }, {
      opacity: 1,
      duration: 1,
      stagger: 0.1,
      delay: 0.5,
      scrollTrigger: {
        trigger: '.technologies',
        start: 'top 30%',
        toggleActions: 'play none none reverse',
        immediateRender: false
      }
    });
    gsap.fromTo('.weUseText', {
      opacity: 1,
      scale: 1
    }, {
      opacity: 0,
      scale: 0.5,
      duration: 1,
      delay: 2.5,
      scrollTrigger: {
        trigger: '.technologies',
        start: 'top 30%',
        toggleActions: 'play none none reverse',
        immediateRender: false
      }
    });
  }, []);
  return (
    <div className="technologies text-center">
      <div className="technologies-all flex justify-center items-center h-screen">
        <div className="orbite md:animate-[spin_120s_linear_infinite] absolute border-2 border-dotted border-gray-400 w-[80vw] h-[80vw] md:w-[50vw] md:h-[50vw] max-w-[800px] max-h-[800px] rounded-full" />
        <div>
          {technologies.map((tech, index) => (
            <TechChild
              key={index}
              title={tech.title}
              icon={tech.icon}
              category={tech.category}
              rotation={(360 / technologies.length) * (index + 1)}
              large={tech.large}
              fontSize={tech.fontSize}
              highlight={hoveredCategory === tech.category}
              setHoveredCategory={setHoveredCategory}
            />
          ))}
        </div>
        <h1 className="weUseText text-3xl md:text-4xl absolute z-10">we use</h1>
        <div className="
            logo-technologies-overlay
            2xl:w-80
            2xl:h-80
            xl:w-64
            xl:h-64
            md:w-52
            md:h-52
            w-32
            h-32
            bg-[#030b17]/10
            relative
            before:absolute
            before:w-full
            before:h-full
            before:-z-10
            before:bg-gradient-to-r
            before:from-pink-600
            before:to-purple-600
            before:left-0
            before:top-0
            before:blur-lg
            before:opacity-75
            rounded-full
            before:rounded-full
            ml-auto
            mr-auto
            flex
            justify-center
            align-center
            border-2
            border-gray-900
          "
        >
          <img src={logoSvg} alt="logo" className="logo-technologies ml-auto mr-auto max-w-fit p-3 md:p-8 drop-shadow-2xl" />
        </div>
      </div>

    </div>
  );
};
export default Technologies;
