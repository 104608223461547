import { useEffect, useState } from 'react';
import { ScatterBoxLoader } from 'react-awesome-loaders';
import { gsap } from 'gsap';

const Loader = ({ enable }) => {
  const [isLoading, setLoading] = useState(enable);
  useEffect(() => {
    if (!enable) {
      gsap.to('.loader', {
        opacity: 0,
        display: 'none',
        onComplete: () => setLoading(false)
      });
    }
  }, [enable]);

  if (isLoading) {
    return (
      <div className="loader z-20 flex justify-center items-center fixed inset-0 bg-[#030b17]">
        <ScatterBoxLoader
          primaryColor="#6366F1"
          background="#030b17"
          duration={6}
        />
      </div>
    );
  }
  return (
    <div />
  );
};
export default Loader;
